import React, { useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  AppBar,
  Box,
  FormControl,
  Input,
  InputAdornment,
  IconButton,
  Button,
  Toolbar,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { cultureService } from "../../../services/CultureService";
import { CultureQuery } from "../../Cultures/Cultures";
import { ICulture } from "../../../../../shared/models/parcelle";
import {
  IClient,
  IYieldRegionsClient,
} from "../../../../../shared/models/regions";
import { userService } from "../../../services/UserService";
import { authService } from "../../../services/AuthService";
import { yieldRegionService } from "../../../services/YieldRegionService";
import { YieldRegionQuery } from "../Yieldregions";

import "./Filters.css";
import { select } from "d3-selection";

export enum YieldScale {
  E1 = "E1", // E1 for regions
  E2 = "E2", // E2 for counties / departement
  E3 = "E3", // E3 for PRA
  E4 = "E4", // E4 for townships
}

export const MIN_YEAR = 2023;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      top: "60px",
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    buttons: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    title: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export interface FilterProps {
  onChangeCulture?: (culture: any, crop: any) => void;
  onChangeView?: (view: any) => void;
  onChangeRegion?: (region: any) => void;
  onChangeClient?: (cl: any) => void;
  onChangeYear?: (year: any) => void;

  crop?: string;
  vue?: YieldScale | unknown;
  regions?: IYieldRegionsClient[] | undefined;
  region?: string | unknown;
  command?: any;
  client?: IClient | unknown;
  disabled?: boolean;
  year?: number;
}

export const Filters = ({
  crop,
  vue,
  region,
  client,
  onChangeCulture,
  onChangeView,
  onChangeRegion,
  onChangeClient,
  onChangeYear,
  command,
  disabled,
  year,
}: FilterProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cultures, setCultures] = useState<ICulture[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);

  const [years, setYears] = useState<number[]>([]);

  const [disabledMode, setDisabledMode] = useState<boolean>(disabled === true);

  const [selectedCulture, setSelectedCulture] = useState<string | unknown>(
    crop
  );
  const [vues, setVues] = useState(
    authService!.user!.role.includes("admin")
      ? Object.keys(YieldScale)
      : authService.user?.views
  );
  const [selectedVue, setSelectedVue] = useState<string | unknown>(vue);
  const [regionsState, setRegionsState] = useState<
    IYieldRegionsClient[] | undefined
  >([]);
  const [selectedRegion, setSelectedRegion] = useState<string | unknown>(
    region
  );

  const [selectedClient, setSelectedClient] = useState<string | unknown>(
    client
  );

  const [selectedYear, setSelectedYear] = useState<number | unknown>(
    yieldRegionService.getEfectiveYear(year)
  );

  useEffect(() => {
    setDisabledMode(disabled === true);
  }, [disabled]);

  const loadYears = async (callback:()=>void) => {
    if(selectedClient){
      try{
        const result = await yieldRegionService.getValidYears(selectedClient as string);
        setYears(result);
        if(!result.includes(selectedYear as number)){
          setSelectedYear(result[0]);
        }
        setTimeout(callback, 500);
      }catch(err){
        console.error(err);
      }
    }


  }

  const loadData = async () => {

    try {
      if(selectedClient && selectedCulture && selectedYear){
        const regions = await yieldRegionService.getYieldRegionByLevel(
          new YieldRegionQuery(
            0,
            0,
            "name",
            "asc",
            "E1",
            undefined,
            selectedCulture as string,
            selectedClient as string,
            selectedYear as number,
          ), true
        );
        
        setRegionsState(regions && regions.items ? regions.items : []);
      }
    } catch (e) {
      setRegionsState([]);
    }

    const culturesDataSet = await userService.getCultures();
    setCultures(
      culturesDataSet.filter(
        (c) =>
          (authService &&
            authService.token &&
            authService.user &&
            authService.user.cultures &&
            authService.user!.cultures!.indexOf(c?._id as any) > -1) ||
          authService!.user!.role.includes("admin")
      )
    );

    if (authService!.user!.role.includes("admin")) {
      const cs = await yieldRegionService.getClients();
      setClients(cs);
      if (!selectedClient) {
        setSelectedClient(cs[0]._id);
      }
    } else {
      setClients(authService.user?.clients || []);
      if (
        !selectedClient &&
        authService.user &&
        authService.user.clients &&
        authService.user?.clients?.length > 0
      ) {
        setSelectedClient(authService.user!.clients[0]!._id);
      }
    }
  };

  useEffect(() => {
    const crop = cultures.find((c) => c.abbr === selectedCulture);
    if (onChangeCulture) onChangeCulture(selectedCulture, crop);
    loadData();
  }, [selectedCulture]);

  useEffect(() => {
    if (onChangeRegion) onChangeRegion(selectedRegion);
  }, [selectedRegion]);

  useEffect(() => {
    if (onChangeView) onChangeView(selectedVue);
    loadData();

  }, [selectedVue]);

  useEffect(() => {
    if (onChangeClient) onChangeClient(selectedClient);
    loadYears(()=>{
      loadData();
    });

  }, [selectedClient]);

  useEffect(() => {
    if (onChangeYear) onChangeYear(selectedYear);
  }, [selectedYear]);

  useEffect(() => {
    setSelectedVue(vue);
  }, [vue]);

  useEffect(() => {
    setSelectedCulture(crop);
  }, [crop]);

  useEffect(() => {
    setSelectedRegion(region);
  }, [region]);

  useEffect(() => {
    if (year) {
      setSelectedYear(year);
    }
  }, [year]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar className={disabledMode ? "disabled" : ""}>
        <Grid container spacing={1}>
          <Grid xs={6} sm={1} item>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="selected-client">{t("Client")}</InputLabel>
              <Select
                labelId="selected-culture"
                id="selected-client-select"
                value={selectedClient ? selectedClient : ""}
                onChange={(sel) => setSelectedClient(sel.target.value)}
                label={t("Clients")}
              >
                {clients.map((client) => (
                  <MenuItem key={client._id} value={client._id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={2} item>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="selected-culture">{t("Crop")}</InputLabel>
              <Select
                labelId="selected-culture"
                id="selected-culture-select"
                value={selectedCulture ? selectedCulture : ""}
                onChange={(sel) => setSelectedCulture(sel.target.value)}
                label={t("Culture")}
              >
                <MenuItem value={""}>{t("All")}</MenuItem>
                {cultures.map((culture) => (
                  <MenuItem key={culture._id} value={culture.abbr}>
                    {culture.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4} sm={1} item>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="selected-year">{t("Year")}</InputLabel>
              <Select
                labelId="selected-year"
                id="selected-year-select"
                value={selectedYear ? selectedYear : ""}
                onChange={(sel) => setSelectedYear(sel.target.value)}
                label={t("Year")}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sm={1} item>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="selected-vue">{t("View")}</InputLabel>
              <Select
                labelId="selected-vue"
                id="selected-vue-select"
                value={selectedVue ? selectedVue : ""}
                onChange={(sel) => setSelectedVue(sel.target.value)}
                label={t("View")}
              >
                {vues?.sort((a,b)=>a.localeCompare(b)).map((vue) => (
                  <MenuItem key={vue} value={vue}>
                    {t(vue)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>



          {authService.user && (authService.user.restriction === undefined || authService.user.restriction.length<=0) ? (
            <Grid xs={6} sm={2} item>
              <FormControl
                disabled={!selectedVue && !regionsState}
                variant="outlined"
                size="small"
                fullWidth
              >
                <InputLabel id="selected-region">{t("Region")}</InputLabel>
                <Select
                  labelId="selected-region"
                  id="selected-region-select"
                  value={selectedRegion ? selectedRegion : ""}
                  onChange={(sel) => setSelectedRegion(sel.target.value)}
                  label={t("Region")}
                >
                  <MenuItem value={""}>{t("All")}</MenuItem>
                  {regionsState?.map((region) => (
                    <MenuItem
                      key={region.region_id!._id}
                      value={region.region_id!._id}
                    >
                      {region.region_id!.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          <Grid className="commands" item>
            {command}
          </Grid>
        </Grid>
        <span className={classes.title}></span>
        <div className={classes.buttons}></div>
      </Toolbar>
    </AppBar>
  );
};
